<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Sign in</h2>
        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" lg="6">
            <validation-observer ref="obs" v-slot="{invalid,validated,passes}">
              <v-card flat>
                <v-card-text>
                  <validation-provider name="ユーザーID" rules="required" v-slot="{errors,valid}">
                    <v-text-field
                      v-model="user"
                      label="ユーザーID"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider name="パスワード" rules="required" v-slot="{errors,valid}">
                    <v-text-field
                      v-model="password"
                      label="パスワード"
                      outlined
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      counter
                      :error-messages="errors"
                      :success="valid"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </validation-provider>
                  <router-link to="/password">パスワードをお忘れの方はこちら</router-link>
                </v-card-text>
                <v-divider class="mt-6"></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn
                    class="my-6"
                    color="primary"
                    :loading="isLogin"
                    @click="passes(login)"
                    :disabled="invalid || !validated || isLogin"
                  >サインイン</v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
export default {
  name: "TheSignin",
  data: () => ({
    user: "",
    password: "",
    show1: false,
    isLogin: false
  }),
  created() {
    const myload = () => {
        if (!firebase.auth()) {
          return;
        }
        window.clearInterval(myId);
        const firebaseUser = firebase.auth().currentUser;
        //ログインしていない場合、ローカルストレージを削除
        if (!firebaseUser) {
          window.localStorage.removeItem("search-product-pa");
          return;
        }
        this.$router.push("/");
      },
      myId = window.setInterval(myload, 3000);
  },
  methods: {
    login() {
      if (this.isLogin) {
        return false;
      }
      this.isLogin = true;
      const auth = firebase.auth(),
        promise = auth.signInWithEmailAndPassword(this.user, this.password);
      promise
        .then(fUser => {
          window.localStorage.removeItem("search-product-pa");
          window.localStorage.setItem(
            "search-product-pa",
            JSON.stringify({ uid: fUser.user.uid })
          );
          this.$router.push("/");
        })
        .catch(e => {
          this.isLogin = false;
          if (e.code === "auth/wrong-password") {
            window.alert("パスワードが違います。");
            return;
          }
          window.alert("ユーザーIDをご確認ください。");
        });
    }
  }
};
</script>
