<template>
  <mysignin />
</template>

<script>
// @ is an alias to /src
import mysignin from "@/components/TheSignin.vue";

export default {
  name: "Signin",
  components: {
    mysignin
  }
};
</script>
